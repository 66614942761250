/* For WebKit-based browsers (Chrome, Safari, Opera) */
::-webkit-scrollbar {
  width: 4px; /* Slim scrollbar width */
  opacity: 0; /* Hide the scrollbar by default */
  transition: opacity 0.3s ease-in-out; /* Add a smooth transition effect */
}

::-webkit-scrollbar-track {
  background-color: transparent; /* Transparent track */
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent dark gray thumb */
  border-radius: 3px; /* Slightly rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4); /* Darker thumb on hover */
}

/* Show the scrollbar only when the element is hovered over */
body:hover::-webkit-scrollbar {
  opacity: 0.2; /* Show the scrollbar */
}

/* For Internet Explorer and Edge */
body {
  scrollbar-face-color: rgba(0, 0, 0, 0.2); /* Semi-transparent dark gray thumb */
  scrollbar-track-color: transparent; /* Transparent track */
  scrollbar-arrow-color: rgba(0, 0, 0, 0.2); /* Semi-transparent dark gray arrows */
  margin: 0px;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .ant-menu-title-content {
  color: #ffffff !important;
}

.ant-menu-dark .ant-menu-item:not(.ant-menu-item-selected) .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item:not(.ant-menu-item-selected) .ant-menu-title-content {
  color: #d9d9d9 !important;
}

/* grey background for selected menu item in light mode */
.ant-menu-light .ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0.08) !important;
}

#dashboard-container iframe {
  width: 100% !important;
  height: 100vh !important;
  border: none;
}

/* SupersetDashboard.css */
.dark-mode {
  filter: invert(100%) hue-rotate(180deg); 
}

/* text-success */
.text-success {
  color: #52c41a;
}

/* text-warning */
.text-warning {
  color: #faad14;
}

/* text-danger */
.text-danger {
  color: #f5222d;
}